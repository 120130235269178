import React from "react"
import styled from "styled-components"

import BlogCard from "../components/styles/BlogCard"
import TagMenu from "../components/TagMenu"
import Layout from "../components/layout"
import TitleTag from "../components/styles/TitleTag"

const Frame = styled.div`
  display: flex;
`

const Indicator = styled.div`
  display: flex;
  margin-bottom: 1em;

  h3 {
    padding-top: 10px;
    padding-right: 1em;
  }
  span {
    margin-bottom: 10px;
  }
`

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { totalCount } = data.allMarkdownRemark

  return (
    <Layout>
      <Indicator>
        <h3>{totalCount} posts with </h3>
        <span>
          <TitleTag title={tag} />
        </span>
      </Indicator>
      <Frame>
        <TagMenu />
        <div style={{ display: "flex", flexDirection: "column" }}>
          {data.allMarkdownRemark.edges.map(({ node }, index) => {
            return (
              <BlogCard
                key={index}
                title={node.frontmatter.title}
                excerpt={node.excerpt}
                link={`/blog${node.fields.slug}`}
                absolute
              />
            )
          })}
        </div>
      </Frame>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

export default Tags
