import { useStaticQuery, graphql } from "gatsby"

export const useTags = () => {
  const tagsQuery = useStaticQuery(
    graphql`
      query Tags {
        allMarkdownRemark {
          group(field: frontmatter___tags) {
            fieldValue
          }
        }
      }
    `
  )

  const group = tagsQuery.allMarkdownRemark.group

  return group.map(i => i.fieldValue)
}
