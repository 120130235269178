import React from "react"
import { Link } from "gatsby"
import { useTags } from "./hooks/useTags"
import TitleTag from "../components/styles/TitleTag"
import styled from "styled-components"

const TagStyle = styled.div`
  margin-right: 1em;
`

const TagMenu = () => {
  const tags = useTags()
  return (
    <TagStyle>
      {tags.map(i => (
        <Link to={`/tags/${i}`}>
          <TitleTag title={i} />
        </Link>
      ))}
    </TagStyle>
  )
}

export default TagMenu
